import api from "./api";

const BASE_URL = process.env.REACT_APP_BACKEND_URL + "/api/v1";

const getHeaderConfig = () => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const authToken = auth?.token;
  if (!authToken) {
    localStorage.clear();
    window.location.href = "/login";
  }
  let headerConfig = {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
  return headerConfig;
};

/* --- AUTHENTICATION --- */
export function registerUser(body) {
  return api.post(`${BASE_URL}/auth/register`, body, { withCredentials: true });
}

export function authenticateUser(body) {
  return api.post(`${BASE_URL}/auth/login`, body, { withCredentials: true });
}

export function authenticateGoogleUser(body) {
  return api.post(`${BASE_URL}/auth/googleLogin`, body, {
    withCredentials: true,
  });
}

/*
export function resetPassword(email) {
  return api.put(`${BASE_URL}/auth/resetPassword/${email}`, {}, { withCredentials: true });
}
*/
/* --- AUTHENTICATION END --- */

/* --- VERIFY --- */
export function resendVerify(body) {
  return api.post(`${BASE_URL}/verify/resend`, body, { withCredentials: true });
}

export function verifyUser(verifyToken) {
  return api.put(
    `${BASE_URL}/verify/${verifyToken}`,
    {},
    { withCredentials: true }
  );
}
/* --- VERIFY END --- */

/* --- FORGOT PASSWORD --- */
export function forgotPasswordSendMail(email) {
  return api.post(
    `${BASE_URL}/forgotPassword/sendMail/${email}`,
    {},
    { withCredentials: true }
  );
}

export function checkForgotPasswordEmail(email) {
  return api.get(`${BASE_URL}/forgotPassword/check/${email}`, {
    withCredentials: true,
  });
}

export function resetPassword(email, password) {
  return api.post(
    `${BASE_URL}/forgotPassword/resetPassword/${email}/${password}`,
    {},
    { withCredentials: true }
  );
}
/* --- FORGOT PASSWORD END --- */

/* --- USER --- */
export function saveMemberByUser(body) {
  return api.post(`${BASE_URL}/user/member`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getMembersByOrganization(organizationId) {
  return api.get(`${BASE_URL}/user/member/organization/${organizationId}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getUserByEmail(email) {
  return api.get(`${BASE_URL}/user/${email}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function updateName(body) {
  return api.put(`${BASE_URL}/user/updateName`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function updateEmail(token) {
  return api.put(
    `${BASE_URL}/user/updateEmail`,
    { token },
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function updatePassword(token) {
  return api.put(
    `${BASE_URL}/user/updatePassword`,
    { token },
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function deleteUserById(id) {
  return api.delete(`${BASE_URL}/user/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function deleteMemberById(id) {
  return api.delete(`${BASE_URL}/user/member/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function allUsers() {
  return api.get(`${BASE_URL}/user/all`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}
/* --- USER END ---*/

/* --- CONFIRMATION CODE ---*/
export function sendChangeEmailConfirmationCode(email) {
  return api.post(
    `${BASE_URL}/confirmation/changeEmail/${email}`,
    {},
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function sendChangePasswordConfirmationCode(body) {
  return api.post(`${BASE_URL}/confirmation/changePassword`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function sendConfirmConfirmationCode(code, email) {
  return api.get(
    `${BASE_URL}/confirmation/sendConfirm/code/${code}/email/${email}`,
    { ...getHeaderConfig(), withCredentials: true }
  );
}
/* --- END CONFIRMATION CODE ---*/

/* --- WORKSPACE --- */
export function saveWorkspace(body) {
  return api.post(`${BASE_URL}/workspace`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getAllWorkspaces() {
  return api.get(`${BASE_URL}/workspace`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function renameWorkspaceById(id, name) {
  return api.put(`${BASE_URL}/workspace/rename/${id}`, name, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function moveToWorkspaceByIdAndFormId(workspaceId, formId) {
  return api.get(
    `${BASE_URL}/workspace/moveTo/workspace/${workspaceId}/form/${formId}`,
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function deleteWorkspaceById(id) {
  return api.delete(`${BASE_URL}/workspace/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function allWorkspaces() {
  return api.get(`${BASE_URL}/workspace/all`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}
/* --- END WORKSPACE ---*/

/* --- THEME --- */
export function saveTheme(body) {
  return api.post(`${BASE_URL}/themes`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getThemesByOrganization(organizationId) {
  return api.get(`${BASE_URL}/themes/organization/${organizationId}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getThemeById(id) {
  return api.get(`${BASE_URL}/themes/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function deleteThemeById(id) {
  return api.delete(`${BASE_URL}/themes/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}
/* --- END THEME --- */

/* --- FORM --- */
export function initializeForm(body) {
  return api.post(`${BASE_URL}/forms/initialize`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function saveForm(body) {
  return api.post(`${BASE_URL}/forms`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function updateForm(body) {
  return api.put(`${BASE_URL}/forms`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function publishForm(id) {
  return api.put(
    `${BASE_URL}/forms/publish/${id}`,
    {},
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function updateField(body) {
  return api.put(`${BASE_URL}/forms/field`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function updateOptions(body) {
  return api.put(`${BASE_URL}/forms/options`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function listByWorkspace(workspaceId) {
  return api.get(`${BASE_URL}/forms/listByWorkspace/${workspaceId}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getFormById(formId) {
  return api.get(`${BASE_URL}/forms/${formId}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function sendMailForForm(body) {
  return api.post(`${BASE_URL}/forms/sendEmail`, body, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function renameFormById(id, name) {
  return api.put(`${BASE_URL}/forms/rename/${id}`, name, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function duplicateFormById(id) {
  return api.post(
    `${BASE_URL}/forms/duplicate/${id}`,
    {},
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function copyToWorkspaceByIdAndFormId(workspaceId, formId) {
  return api.get(
    `${BASE_URL}/forms/copyTo/workspace/${workspaceId}/form/${formId}`,
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function deleteFormById(id) {
  return api.delete(`${BASE_URL}/forms/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function deletePageById(id) {
  return api.delete(`${BASE_URL}/forms/deletePage/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function deleteFieldById(id) {
  return api.delete(`${BASE_URL}/forms/deleteField/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getHeadersById(formId) {
  return api.get(`${BASE_URL}/forms/headers/${formId}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function updateFormHeaders(formHeaders) {
  return api.put(`${BASE_URL}/forms/updateHeaders`, formHeaders, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function deleteExternalPageById(formId, externalId) {
  return api.delete(
    `${BASE_URL}/forms/externalPage/form/${formId}/external/${externalId}`,
    { ...getHeaderConfig(), withCredentials: true }
  );
}

export function allForms() {
  return api.get(`${BASE_URL}/forms/all`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}
/* --- END FORM --- */

/* TRACKING */
export function getTrackingByFormId(formId) {
  return api.get(`${BASE_URL}/tracking/listByForm/${formId}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}
/* --- EBD TRACKING --- */

/* RESPONSE */
export function getResponsesByFormId(formId) {
  return api.get(`${BASE_URL}/response/listByForm/${formId}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function getResponseById(id) {
  return api.get(`${BASE_URL}/response/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}

export function deleteResponseById(id) {
  return api.delete(`${BASE_URL}/response/${id}`, {
    ...getHeaderConfig(),
    withCredentials: true,
  });
}
/* --- END RESPONSE --- */

/* --- CONTACT --- */
export function saveContactForm(body) {
  return api.post(`${BASE_URL}/contact`, body, { withCredentials: true });
}

export function getAllContactForms() {
  return api.get(`${BASE_URL}/contact/all`, {
    withCredentials: true,
  });
}

export function deleteContactById(id) {
  return api.delete(`${BASE_URL}/contact/${id}`, { withCredentials: true });
}
/* --- END CONTACT --- */

/* --- SUBSCRIBE --- */
export function saveSubscribe(body) {
  return api.post(`${BASE_URL}/subscribe`, body, { withCredentials: true });
}

export function getAllSubscribes() {
  return api.get(`${BASE_URL}/subscribe/all`, {
    withCredentials: true,
  });
}

export function deleteSubscribeById(id) {
  return api.delete(`${BASE_URL}/subscribe/${id}`, { withCredentials: true });
}
/* --- END SUBSCRIBE --- */
