import { createContext, useContext, useState } from "react";
import { loadAuthState, storageAuthState } from "./ContextStorage";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authUser, setAuthUser] = useState(loadAuthState());
  const [registeredUser, setRegisteredUser] = useState();

  const onLoginSuccess = (data) => {
    setAuthUser(data);
    storageAuthState(data);
  };

  const logout = () => {
    setAuthUser(null);
    storageAuthState(null);
  };

  return (
    <AuthContext.Provider
      value={{
        registeredUser,
        setRegisteredUser,
        authUser,
        setAuthUser,
        onLoginSuccess,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}
