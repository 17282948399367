import "./AnalyticCard.css";

const AnalyticCard = ({ title, content, onClick }) => {
  return (
    <div className="analyticCard-container" onClick={onClick}>
      <div className="AnalyticCard-title">{title}</div>
      <div className="AnalyticCard-content">{content}</div>
    </div>
  );
};

export default AnalyticCard;
