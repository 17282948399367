import "./Navbar.css";
import logo from "../../../assets/images/logo/logo-title-30.png";
import { useEffect, useRef, useState } from "react";
import { FaRegUser } from "react-icons/fa6";
import { TbUsersGroup } from "react-icons/tb";
import { IoPowerOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const Navbar = () => {
  const [isExpandedProfile, setExpandedProfile] = useState(false);
  const profileMenuRef = useRef();

  const auth = useAuth();

  const navigation = useNavigate();

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClicks);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks);
    };
  }, [isExpandedProfile]);

  const handleOutsideClicks = (event) => {
    if (
      event.target.className !== "navbar-profile-container" &&
      event.target.className !== "navbar-profile-expanded-title" &&
      event.target.className !== "navbar-profile-title" &&
      profileMenuRef &&
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target)
    ) {
      setExpandedProfile(false);
    }
  };

  const handleOpenProfileMenu = () => {
    setExpandedProfile(!isExpandedProfile);
  };

  const gotoMembersPage = () => {
    navigation(`/members`);
  };

  const logout = () => {
    auth.logout();
  };

  return (
    <div className="navbar-container">
      <div className="navbar-logo-container" onClick={() => navigation("/")}>
        <img src={logo} alt="logo" />
        <div
          style={{
            display: "flex",
          }}
        ></div>
      </div>
      {auth && auth.authUser && auth.authUser.token ? (
        <>
          <div
            className="navbar-profile-container"
            onClick={handleOpenProfileMenu}
          >
            <div className="navbar-profile-title">
              {auth?.authUser?.firstName?.slice(0, 1).toUpperCase()}
              {auth?.authUser?.lastName?.slice(0, 1).toUpperCase()}
            </div>
          </div>

          <div
            ref={profileMenuRef}
            className={`navbar-profile-expanded ${
              !isExpandedProfile ? "hide" : ""
            } `}
          >
            <div className="navbar-profile-expanded-wrapper">
              <div className="navbar-profile-expanded-container">
                <div className="navbar-profile-expanded-title">
                  {auth.authUser.firstName.slice(0, 1).toUpperCase()}
                  {auth.authUser.lastName.slice(0, 1).toUpperCase()}
                </div>
              </div>

              <div className="navbar-profile-expanded-name-container">
                <div className="navbar-profile-expanded-name">
                  {auth.authUser.firstName} {auth.authUser.lastName}
                </div>
                <div className="navbar-profile-expanded-email">
                  {auth.authUser.email}
                </div>
              </div>
            </div>
            <div className="navbar-profile-expanded-menu">
              <div
                className="navbar-profile-expanded-menu-item"
                onClick={() => navigation("/account")}
              >
                <div className="navbar-profile-expanded-menu-icon">
                  <FaRegUser />
                </div>
                <div className="navbar-profile-expanded-menu-title">
                  Your Account
                </div>
              </div>

              {/*
            <div className="navbar-profile-expanded-menu-item">
              <div className="navbar-profile-expanded-menu-icon">
                <SlShareAlt />
              </div>
              <div className="navbar-profile-expanded-menu-title">
                Domain Settings
              </div>
          </div>
          */}
              {auth.authUser.role !== "USER" && (
                <div
                  className="navbar-profile-expanded-menu-item"
                  onClick={gotoMembersPage}
                >
                  <div className="navbar-profile-expanded-menu-icon">
                    <TbUsersGroup />
                  </div>
                  <div className="navbar-profile-expanded-menu-title">
                    Members
                  </div>
                </div>
              )}
              {/*
              <div className="navbar-profile-expanded-menu-item">
                <div className="navbar-profile-expanded-menu-icon">
                  <IoMdHelpCircleOutline />
                </div>
                <div className="navbar-profile-expanded-menu-title">
                  Help Center
                </div>
              </div>
              */}
            </div>
            <div className="navbar-profile-expanded-logout">
              <div
                className="navbar-profile-expanded-menu-item"
                style={{ marginBottom: "0px" }}
                onClick={logout}
              >
                <div className="navbar-profile-expanded-menu-icon">
                  <IoPowerOutline />
                </div>
                <div className="navbar-profile-expanded-menu-title">Logout</div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="navbar-loginContainer">
          <div
            className="navbar-login-signinButton"
            onClick={() =>
              navigation("/login", { state: { activeTab: "signIn" } })
            }
          >
            Sign in
          </div>
          <div
            className="navbar-login-signupButton"
            onClick={() =>
              navigation("/login", { state: { activeTab: "signUp" } })
            }
          >
            Sign up
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
