import { useEffect, useState } from "react";
import "./App.css";
import "./css/CustomAntd.css";

import { useAuth } from "./context/AuthContext";
import Navigation from "./navigation/navigation";
import { MainProvider } from "./context/MainContext";
import { jwtDecode } from "jwt-decode";
import ReactGA from "react-ga4";

function App() {
  const [selectedWorkspace, setSelectedWorkspace] = useState("");

  const auth = useAuth();

  ReactGA.initialize("G-B961VS2PVW");
  ReactGA.send({ hitType: "pageview", page: document.location.pathname });

  useEffect(() => {
    const authData = JSON.parse(localStorage.getItem("auth"));

    if (!authData || !authData.token) {
      return;
    }
    const { token } = authData;
    try {
      const decodedToken = jwtDecode(token);

      const currentTime = Date.now() / 1000;
      const timeUntilExpiration = decodedToken.exp - currentTime;
      if (timeUntilExpiration <= 0) {
        auth.logout();
      } else {
        const timer = setTimeout(() => {
          auth.logout();
        }, timeUntilExpiration * 1000);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      auth.logout();
    }
  }, [auth]);

  const data = {
    selectedWorkspace,
    setSelectedWorkspace,
  };

  return (
    <MainProvider value={data}>
      <div className="App">
        <Navigation />
      </div>
    </MainProvider>
  );
}

export default App;
