import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React, { Suspense } from "react";
import PrivateRoute from "./PrivateRoute";
import SmallLoading from "../components/UI/Loading/SmallLoading";
import AdminDashboard from "../pages/admin/AdminDashboard";
import Contact from "../pages/contact/Contact";
import AdminContacs from "../pages/admin/AdminContacts";

// Lazy-loaded pages
const Dashboard = React.lazy(() => import("../pages/dashboard/Dashboard"));
const Login = React.lazy(() => import("../pages/login/Login"));
const NotFound = React.lazy(() => import("../pages/notfound/NotFound"));
const Verify = React.lazy(() => import("../pages/login/verify/Verify"));
const VerifyUser = React.lazy(() => import("../pages/login/verify/VerifyUser"));
const ForgotPassword = React.lazy(() =>
  import("../pages/login/forgotPassword/ForgotPassword")
);
const Workspace = React.lazy(() => import("../pages/workspace/Workspace"));
const SelectForm = React.lazy(() =>
  import("../pages/form/SelectForm/SelectForm")
);
const StandartForm = React.lazy(() =>
  import("../pages/form/NewForm/StandartForm/StandartForm")
);
const PublishPage = React.lazy(() =>
  import("../pages/form/Publish/PublishPage")
);
const FForm = React.lazy(() => import("../pages/fform/FForm"));
const ViewForm = React.lazy(() => import("../pages/viewForm/ViewForm"));
const ResultPage = React.lazy(() => import("../pages/form/Result/ResultPage"));
const Members = React.lazy(() => import("../pages/member/Members"));
const NotWorkspace = React.lazy(() =>
  import("../pages/notWorkspace/NotWorkspace")
);
const ResetPassword = React.lazy(() =>
  import("../pages/login/resetPassword/ResetPassword")
);
const Account = React.lazy(() => import("../pages/account/Account"));
const ChangeEmailConfirmation = React.lazy(() =>
  import("../pages/confirmation/ChangeEmailConfirmation")
);
const ChangePasswordConfirmation = React.lazy(() =>
  import("../pages/confirmation/ChangePasswordConfirmation")
);
const LandingPage = React.lazy(() =>
  import("../pages/landingPage/LandingPage")
);

const Navigation = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
    },
    {
      path: "/dashboard",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <Dashboard />
        </PrivateRoute>
      ),
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/login/verify",
      element: <Verify />,
    },
    {
      path: "/verifyUser/:verifyToken",
      element: <VerifyUser />,
    },
    {
      path: "/login/forgotPassword",
      element: <ForgotPassword />,
    },
    {
      path: "/resetPassword/:email/:token",
      element: <ResetPassword />,
    },
    {
      path: "/workspace/:uniqueId",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <Workspace />
        </PrivateRoute>
      ),
    },
    {
      path: "/notFound",
      element: <NotFound />,
    },
    {
      path: "/notAuthorization",
      element: <NotWorkspace />,
    },
    {
      path: "*",
      exact: true,
      element: <NotFound />,
    },
    {
      path: "/workspace/:uniqueId/form/select",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <SelectForm />
        </PrivateRoute>
      ),
    },
    {
      path: "/workspace/:uniqueId/form/:formId",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <StandartForm />
        </PrivateRoute>
      ),
    },
    {
      path: "/workspace/:workspaceId/form/:formId/share",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <PublishPage />
        </PrivateRoute>
      ),
    },
    {
      path: "form/:id",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <FForm />
        </PrivateRoute>
      ),
    },
    {
      path: "/:id",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <ViewForm />
        </PrivateRoute>
      ),
    },
    {
      path: "/workspace/:workspaceId/form/:formId/results",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <ResultPage />
        </PrivateRoute>
      ),
    },
    {
      path: "/workspace/:workspaceId/form/:formId/response/:responseId",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <ResultPage />
        </PrivateRoute>
      ),
    },
    {
      path: "/members",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "SUPERVISOR"]}>
          <Members />
        </PrivateRoute>
      ),
    },
    {
      path: "/account",
      element: (
        <PrivateRoute allowedRoles={["ADMIN", "USER", "SUPERVISOR"]}>
          <Account />
        </PrivateRoute>
      ),
    },
    {
      path: "/changeEmail/:token",
      element: <ChangeEmailConfirmation />,
    },
    {
      path: "/changePassword/:token",
      element: <ChangePasswordConfirmation />,
    },
    {
      path: "/adminDashboardPanel",
      element: <AdminDashboard />,
    },
    {
      path: "/contact",
      element: <Contact />,
    },
    { path: "/adminDashboardPanel/contacs", element: <AdminContacs /> },
  ]);

  return (
    <Suspense fallback={<SmallLoading />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};

export default Navigation;
