import { Col, Form, Row, message } from "antd";
import Navbar from "../../components/UI/Navbar/Navbar";
import "./Contact.css";
import { useState } from "react";
import { saveContactForm } from "../../services/http";
import SmallLoading from "../../components/UI/Loading/SmallLoading";
import { useNavigate } from "react-router-dom";

const Contact = () => {
  const [loading, setLoading] = useState(false);
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [detail, setDetail] = useState();

  const [messageApi, contextHolder] = message.useMessage();

  const navigate = useNavigate();

  const onChangeFullName = (e) => {
    setFullName(e.target.value);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangeSubject = (e) => {
    setSubject(e.target.value);
  };

  const onChangeDetail = (e) => {
    setDetail(e.target.value);
  };

  const onSubmit = async () => {
    const request = {
      type: "CONTACT",
      name: fullName,
      email: email,
      subject: subject,
      detail: detail,
    };
    try {
      setLoading(true);
      const response = await saveContactForm(request);
      if (response.status === 200) {
        setTimeout(() => {
          messageApi.open({
            type: "success",
            content: "Sended contact form",
          });
        }, 300);

        setTimeout(() => {
          setLoading(true);
        }, 1500);

        setTimeout(() => {
          navigate("/");
          setLoading(false);
        }, 3000);
      }
    } catch (err) {
      console.log("Sending contact form error : ", err);
      setTimeout(() => {
        messageApi.open({
          type: "error",
          content: "A problem occurred. Please try again later.",
        });
      }, 300);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <SmallLoading />;
  }

  return (
    <>
      {contextHolder}
      <Navbar />
      <Form className="contact-container" onFinish={onSubmit}>
        <Row style={{ width: "100%" }}>
          <Col
            className="contact-form-container"
            xs={{ flex: "0%" }}
            sm={{ flex: "0%" }}
            md={{ flex: "0%" }}
            lg={{ flex: "10%" }}
            xl={{ flex: "15%" }}
          ></Col>
          <Col
            xs={{ flex: "100%" }}
            sm={{ flex: "100%" }}
            md={{ flex: "100%" }}
            lg={{ flex: "75%" }}
            xl={{ flex: "60%" }}
          >
            <Row
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "50px",
              }}
            >
              <Col
                xs={{ flex: "100%" }}
                sm={{ flex: "100%" }}
                md={{ flex: "100%" }}
                lg={{ flex: "40%" }}
                xl={{ flex: "40%" }}
                className="contact-form-title"
              >
                How can we help?
              </Col>

              <Col
                xs={{ flex: "95%" }}
                sm={{ flex: "80%" }}
                md={{ flex: "80%" }}
                lg={{ flex: "50%" }}
                xl={{ flex: "50%" }}
                className="contact-form-ask-responseContainer"
              >
                <div className="contact-form-ask-response-title">
                  Average Response Time
                </div>
                <div className="contact-form-ask-response-time-container">
                  <div className="contact-form-ask-response-time">2 Hours</div>
                </div>
              </Col>
            </Row>

            <Row className="contact-form-field">
              <Col
                xs={{ flex: "100%" }}
                sm={{ flex: "100%" }}
                md={{ flex: "100%" }}
                lg={{ flex: "50%" }}
                xl={{ flex: "50%" }}
                style={{ marginBottom: "30px" }}
              >
                <div className="contact-form-field-label">Your name</div>
                <Form.Item
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your name",
                    },
                  ]}
                >
                  <input
                    className="contact-form-field-input"
                    placeholder="Enter your full name e.g., Jane Doe "
                    onChange={onChangeFullName}
                  />
                </Form.Item>
              </Col>
              <Col
                xs={{ flex: "100%" }}
                sm={{ flex: "100%" }}
                md={{ flex: "100%" }}
                lg={{ flex: "50%" }}
                xl={{ flex: "50%" }}
                style={{ marginBottom: "30px" }}
              >
                <div className="contact-form-field-label">Your email</div>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter valid email address",
                    },
                    {
                      type: "email",
                      message: "Please enter valid email address",
                    },
                  ]}
                >
                  <input
                    className="contact-form-field-input"
                    placeholder="Enter your email address e.g., yourname@example.com"
                    onChange={onChangeEmail}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="contact-form-field">
              <Col span={24} style={{ marginBottom: "30px" }}>
                <div className="contact-form-field-label">
                  The title of your question
                </div>
                <Form.Item
                  name="subject"
                  rules={[
                    {
                      required: true,
                      message: "Please enter subject",
                    },
                  ]}
                >
                  <input
                    className="contact-form-field-input"
                    style={{ width: "95%" }}
                    placeholder="eg, How can I create a successful multiple form?"
                    onChange={onChangeSubject}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="contact-form-field">
              <Col span={24} style={{ marginBottom: "30px" }}>
                <div className="contact-form-field-label">All the details</div>
                <Form.Item
                  name="detail"
                  rules={[
                    {
                      required: true,
                      message: "Please enter details",
                    },
                  ]}
                >
                  <textarea
                    className="contact-form-field-textarea"
                    style={{ width: "95%" }}
                    placeholder="eg, How can I create a successful multiple form?"
                    onChange={onChangeDetail}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Row className="contact-form-field">
              <Col span={24}>
                <Form.Item
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "95%",
                  }}
                >
                  <button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    className="contact-form-field-button"
                  >
                    SUBMIT
                  </button>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/*
          <Col
            className="contact-form-askContainer"
            xs={{ flex: "10%" }}
            sm={{ flex: "10%" }}
            md={{ flex: "10%" }}
            lg={{ flex: "10%" }}
            xl={{ flex: "10%" }}
          >
            <div className="contact-form-ask-title">Ask Your Question</div>
            <div className="contact-form-ask-responseContainer">
              <div className="contact-form-ask-response-title">
                Average Response Time
              </div>
              <div className="contact-form-ask-response-time-container">
                <div className="contact-form-ask-response-time">2 Hours</div>
              </div>
            </div>
          </Col>
          */}
        </Row>
      </Form>
    </>
  );
};

export default Contact;
