export function storageAuthState(auth) {
  localStorage.setItem("auth", JSON.stringify(auth));
}

export function loadAuthState() {
  const defaultState = { id: 0 };
  const authStateInStorage = localStorage.getItem("auth");
  if (!authStateInStorage) return defaultState;
  try {
    return JSON.parse(authStateInStorage);
  } catch {
    return defaultState;
  }
}

export function storageWorkspace(workspace) {
  localStorage.setItem("workspace", JSON.stringify(workspace));
}

export function loadWorkspace() {
  const defaultState = { id: 0, name: "My workspace" };
  const workspaceInStorage = localStorage.getItem("workspace");
  if (!workspaceInStorage) return defaultState;
  try {
    return JSON.parse(workspaceInStorage);
  } catch {
    return defaultState;
  }
}

export function storageOrganization(organization) {
  localStorage.setItem("organization", JSON.stringify(organization));
}

export function loadOrganization() {
  const defaultState = { id: 0, name: "My organization" };
  const organizationInStorage = localStorage.getItem("organization");
  if (!organizationInStorage) return defaultState;
  try {
    return JSON.parse(organizationInStorage);
  } catch {
    return defaultState;
  }
}
