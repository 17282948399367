import { createContext, useContext, useState } from "react";
import {
  loadOrganization,
  loadWorkspace,
  storageOrganization,
  storageWorkspace,
} from "./ContextStorage";

const MainContext = createContext();

export const MainProvider = ({ children }) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(loadWorkspace());
  const [selectedOrganization, setSelectedOrganization] = useState(
    loadOrganization()
  );

  const saveWorkspace = (data) => {
    setSelectedWorkspace(data);
    storageWorkspace(data);
  };

  const saveOrganization = (data) => {
    setSelectedOrganization(data);
    storageOrganization(data);
  };

  return (
    <MainContext.Provider
      value={{
        selectedWorkspace,
        setSelectedWorkspace,
        selectedOrganization,
        setSelectedOrganization,
        saveWorkspace,
        saveOrganization,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export function useMainContext() {
  return useContext(MainContext);
}
