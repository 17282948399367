import { PuffLoader } from "react-spinners";
import "./SmallLoading.css";

const SmallLoading = () => {
  return (
    <div className="smallLoading-container">
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "75px",
          height: "75px",
          borderRadius: "10px",
        }}
      >
        <PuffLoader
          color={"black"}
          loading={true}
          size={50}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    </div>
  );
};

export default SmallLoading;
