import logo from "../../assets/images/logo/logo-title-30.png";
import { Table } from "antd";
import { useLocation } from "react-router-dom";

const AdminContacs = () => {
  const location = useLocation();
  const contacs = location.state?.contacs;

  console.log("contacs : ", contacs);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },

    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
    },
  ];
  return (
    <div className="analyticContacs-container">
      <div className="adminDashboard-title-container">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={logo}
            style={{
              paddingRight: "50px",
              borderRight: "1px solid var(--grey200)",
            }}
            alt="logo"
          />
          <div className="adminDashboard-title" style={{ paddingLeft: "40px" }}>
            Admin Dashboard
          </div>
        </div>
      </div>

      <div className="analyticContacs-table">
        <Table columns={columns} dataSource={contacs} />
      </div>
    </div>
  );
};

export default AdminContacs;
