import { useCallback, useEffect, useState } from "react";
import logo from "../../assets/images/logo/logo-title-30.png";
import "./AdminDashboard.css";
import AnalyticCard from "./AnalyticCard";
import {
  allForms,
  allUsers,
  allWorkspaces,
  authenticateGoogleUser,
  deleteContactById,
  deleteSubscribeById,
  deleteUserById,
  getAllContactForms,
  getAllSubscribes,
} from "../../services/http";
import { Space, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useMainContext } from "../../context/MainContext";
import SmallLoading from "../../components/UI/Loading/SmallLoading";

const AdminDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [originalUsers, setOriginaUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [forms, setForms] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [subscribes, setSubscribes] = useState([]);
  const [selectedUserAction, setSelectedUserAction] = useState("allUsers");

  const navigate = useNavigate();
  const auth = useAuth();
  const mainContext = useMainContext();

  useEffect(() => {
    if (!auth.authUser) {
      navigate("/login");
    }
    if (auth?.authUser?.email === "e.b.egribey@gmail.com") {
      localStorage.setItem("adpt", JSON.stringify(auth.authUser));
    }
    if (auth.authUser.email !== "e.b.egribey@gmail.com") {
      auth.onLoginSuccess(JSON.parse(localStorage.getItem("adpt")));
    }
  }, [auth, navigate]);

  const getAllUsers = useCallback(async () => {
    try {
      setLoading(true);
      const response = await allUsers();
      if (response && response.status === 200) {
        setUsers(response.data);
        setOriginaUsers(response.data);
      }
    } catch (error) {
      console.log("Getting all users error : ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getAllWorkspaces = useCallback(async () => {
    try {
      setLoading(true);
      const response = await allWorkspaces();
      if (response && response.status === 200) {
        setWorkspaces(response.data);
      }
    } catch (error) {
      console.log("Getting all workspaces error : ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getAllForms = useCallback(async () => {
    try {
      setLoading(true);
      const response = await allForms();
      if (response && response.status === 200) {
        setForms(response.data);
      }
    } catch (error) {
      console.log("Getting all workspaces error : ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getContactForms = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAllContactForms();
      if (response && response.status === 200) {
        setContacts(response.data);
      }
    } catch (error) {
      console.log("Getting all workspaces error : ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getSubscribes = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getAllSubscribes();
      if (response && response.status === 200) {
        setSubscribes(response.data);
      }
    } catch (error) {
      console.log("Getting all workspaces error : ", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getAllUsers();
    getAllWorkspaces();
    getAllForms();
    getContactForms();
    getSubscribes();
  }, [
    getAllUsers,
    getAllWorkspaces,
    getAllForms,
    getContactForms,
    getSubscribes,
  ]);

  const onChangeUserAction = (action) => {
    const tempUsers = [...originalUsers];

    if (action === "allUsers") {
      setUsers(originalUsers);
    } else if (action === "adminUsers") {
      const adminUsers = tempUsers.filter((user) => user.role === "ADMIN");
      setUsers(adminUsers);
    } else {
      const userUsers = tempUsers.filter((user) => user.role === "USER");
      setUsers(userUsers);
    }

    setSelectedUserAction(action);
  };

  const userSelect = async (user) => {
    try {
      setLoading(true);
      const response = await authenticateGoogleUser(user);

      if (response.status === 200) {
        auth.onLoginSuccess(response.data);
        mainContext.saveOrganization(response.data.organization);
        const userRole = response.data.role;
        const userWorkspaces = response.data.workspaces;
        if (
          userRole === "USER" &&
          userWorkspaces &&
          userWorkspaces.length > 0
        ) {
          mainContext.saveWorkspace(userWorkspaces[0]);
        } else {
          mainContext.saveWorkspace(
            response.data.organization.defaultWorkspace
          );
        }

        navigate(
          `/workspace/${response.data.organization.defaultWorkspace.uniqueId}`
        );
      }
    } catch (err) {
      console.log("Selecting user error : ", err);
    } finally {
      setLoading(false);
    }
  };

  const deleteContact = async (contact) => {
    try {
      setLoading(true);
      const response = await deleteContactById(contact.id);
      if (response.status === 200) {
        const tempContacts = contacts;
        const deletingContactIndex = contacts.indexOf(contact);
        tempContacts.splice(deletingContactIndex, 1);
        setContacts(tempContacts);
      }
    } catch (err) {
      console.log("Deleting contact error : ", err);
    } finally {
      setLoading(false);
    }
  };

  const deleteSubscribe = async (subscribe) => {
    try {
      setLoading(true);
      const response = await deleteSubscribeById(subscribe.id);
      if (response.status === 200) {
        const tempSubscribes = contacts;
        const deletingSubscribeIndex = contacts.indexOf(subscribe);
        tempSubscribes.splice(deletingSubscribeIndex, 1);
        setSubscribes(tempSubscribes);
      }
    } catch (err) {
      console.log("Deleting subscribe error : ", err);
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (user) => {
    try {
      setLoading(true);
      const response = await deleteUserById(user.id);
      if (response.status === 200) {
        const tempUsers = users;
        const deletingUserIndex = users.indexOf(user);
        tempUsers.splice(deletingUserIndex, 1);
        setUsers(tempUsers);
      }
    } catch (err) {
      console.log("Deleting subscribe error : ", err);
    } finally {
      setLoading(false);
    }
  };

  const logout = () => {
    localStorage.removeItem("adpt");
    auth.logout();
    navigate("/login");
  };

  const columns = [
    {
      title: "First name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      render: (_, record) => (
        <Space size="middle">
          {record.role === "ADMIN" ? (
            <div className="roleAdmin">{record.role}</div>
          ) : (
            <div className="roleUser">{record.role}</div>
          )}
        </Space>
      ),
    },
    {
      title: "Created date",
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: "Organization id",
      dataIndex: "organizationId",
      key: "organizationId",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div
            onClick={() => userSelect(record)}
            className="adminDashboard-user-selectButton"
          >
            Select
          </div>
          <div
            onClick={() => deleteUser(record)}
            className="adminDashboard-deleteButton"
          >
            Delete
          </div>
        </Space>
      ),
    },
  ];

  const contactColumns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
    },

    {
      title: "Detail",
      dataIndex: "detail",
      key: "detail",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div
            onClick={() => deleteContact(record)}
            className="adminDashboard-deleteButton"
          >
            Delete
          </div>
        </Space>
      ),
    },
  ];

  const subscribesColumns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },

    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <div
            onClick={() => deleteSubscribe(record)}
            className="adminDashboard-deleteButton"
          >
            Delete
          </div>
        </Space>
      ),
    },
  ];

  if (loading) {
    return <SmallLoading />;
  }

  return (
    <>
      <div className="adminDashboard-container">
        <div className="adminDashboard-title-container">
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={logo}
              style={{
                paddingRight: "50px",
                borderRight: "1px solid var(--grey200)",
              }}
              alt="logo"
            />
            <div
              className="adminDashboard-title"
              style={{ paddingLeft: "40px" }}
            >
              Admin Dashboard
            </div>
          </div>
          <div className="adminDashboard-logout" onClick={logout}>
            Logout
          </div>
        </div>
        <div className="adminDasboard-analyticContainer">
          <div style={{ display: "flex", alignItems: "center" }}>
            <AnalyticCard title="ALL USERS" content={users.length} />
            <AnalyticCard
              title="ADMIN USERS"
              content={users.filter((u) => u.role === "ADMIN").length}
            />
            <AnalyticCard
              title="MEMBERS"
              content={users.filter((u) => u.role === "USER").length}
            />
            <AnalyticCard title="WORKSPACES" content={workspaces.length} />
            <AnalyticCard title="FORMS" content={forms.length} />
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <AnalyticCard
              title="ALL CONTACTS"
              content={contacts.length}
              onClick={() =>
                navigate(`/adminDashboardPanel/contacs`, {
                  state: {
                    contacts: contacts ? contacts : [],
                  },
                })
              }
            />
            <AnalyticCard title="ALL SUBSCRIBES" content={subscribes.length} />
          </div>
        </div>
        <div className="adminDashboard-usersContainer-actions">
          <div
            onClick={() => onChangeUserAction("allUsers")}
            className={
              selectedUserAction === "allUsers"
                ? "adminDashboard-usersContainer-action-selected"
                : "adminDashboard-usersContainer-action"
            }
          >
            All users
          </div>
          <div
            onClick={() => onChangeUserAction("adminUsers")}
            className={
              selectedUserAction === "adminUsers"
                ? "adminDashboard-usersContainer-action-selected"
                : "adminDashboard-usersContainer-action"
            }
          >
            Admin users
          </div>
          <div
            onClick={() => onChangeUserAction("memberUsers")}
            className={
              selectedUserAction === "memberUsers"
                ? "adminDashboard-usersContainer-action-selected"
                : "adminDashboard-usersContainer-action"
            }
          >
            Member users
          </div>
        </div>
        <div className="adminDashboard-usersContainer">
          <Table columns={columns} dataSource={users} />
        </div>

        <div
          style={{
            padding: "20px 0 0 80px ",
            fontWeight: "500",
          }}
        >
          CONTACTS
        </div>
        <div className="adminDashboard-usersContainer">
          <Table columns={contactColumns} dataSource={contacts} />
        </div>

        <div
          style={{
            padding: "20px 0 0 80px ",
            fontWeight: "500",
          }}
        >
          SUBSCRIBES
        </div>
        <div className="adminDashboard-usersContainer">
          <Table columns={subscribesColumns} dataSource={subscribes} />
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
